import { FC } from "react";
import CheckCircle from "../Icons/CheckCircle";
import ExclamationCircle from "../Icons/ExclamationCircle";
import ErrorIcon from "../Icons/ErrorIcon";
import { InvoiceResult } from "types/dbSchema/invoices";
import { LoadingIndicator } from "../FullscreenLoadingIndicator";

export interface ResultProps {
  result: InvoiceResult;
}

// TODO: Update the processing icon
export const results: InvoiceResult[] = ['Complete', 'Incomplete', 'Failed'];

const Result: FC<ResultProps> = ({ result }) => {

  return (
    <>
      {result === 'Complete' ? (
        <div className="flex items-center gap-0.5 border border-border-complete bg-bg-complete rounded-[100px] px-1 py-0.5">
          <CheckCircle className='w-4 h-4 text-icon-complete' />
          <p className='text-xs font-semibold text-complete uppercase pr-0.5'>Complete</p>
        </div>
      ) : result === 'Incomplete' ? (
        <div className="flex items-center gap-0.5 border border-border-incomplete bg-bg-incomplete rounded-[100px] px-1 py-0.5">
          <ExclamationCircle className='w-4 h-4 text-icon-incomplete' />
          <p className='text-xs font-semibold text-incomplete uppercase pr-0.5'>Incomplete</p>
        </div>
      ) : result === 'Failed' ? (
        <div className="flex items-center gap-0.5 border border-border-failed bg-bg-failed rounded-[100px] px-1 py-0.5">
          <ErrorIcon className='w-4 h-4 text-icon-failed' />
          <p className='text-xs font-semibold text-failed uppercase pr-0.5'>Failed</p>
        </div>
      ) : result === 'Processing' ? (
        <div className="flex items-center gap-0.5 border border-border-processing bg-bg-processing rounded-[100px] px-1 py-0.5">
          <LoadingIndicator className='w-4 h-4 text-icon-processing rounded-full animate-spin border-2 border-solid border-processing border-t-transparent' />
          <p className='text-xs font-semibold text-processing uppercase pr-0.5'>Processing</p>
        </div>
      ) : null}
    </>
  )
}

export const resultFilters = results.map((result) => ({
  label: <Result result={result} />,
  value: result
}))

export default Result