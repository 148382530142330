import { getInvoiceById } from 'api/invoice';
import FullscreenLoadingIndicator from 'components/common/FullscreenLoadingIndicator';
import ViewInvoice from 'components/features/ViewInvoice';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { InvoiceOverview } from 'types/dbSchema/invoices';

const ViewInvoicePage = () => {
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceOverview | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        if (id) {
          const invoice = await getInvoiceById(id);
          setCurrentInvoice(invoice);
        }
      } catch (error) {
        console.error('Error fetching invoice', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchInvoice();
  }, [id]);

  if (isLoading) return <FullscreenLoadingIndicator />;

  if (!currentInvoice) return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <p className="text-2xl text-text-primary font-bold text-center">
        We could not find the Invoice
      </p>
      <p className="text-text-primary text-center">
        The invoice with the ID <span className="font-semibold">{id}</span> could not be found.
      </p>
    </div>
  )

  return <ViewInvoice currentInvoice={currentInvoice} />
}

export default ViewInvoicePage