import AnalyticsChart from 'components/features/AnalyticsChart'
import { FC } from 'react'

const AnalyticsPage: FC = () => {
  return (
    <div className='p-8 gap-8 flex flex-col w-full h-full items-center justify-center max-w-[1600px]'>
      <div className='w-full text-2xl font-semibold'>
        <p className='text-left text-text-primary'>Analytics</p>
      </div>
      <AnalyticsChart />
    </div>
  )
}

export default AnalyticsPage