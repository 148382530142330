import KeysTable from "components/features/KeysTable"
import { FC } from "react"

const KeysPage: FC = () => {
  return (
    <div className='flex flex-col w-full h-full items-center justify-center max-w-[1600px] p-8'>
      <KeysTable />
    </div>
  )
}

export default KeysPage