import { cn } from "lib/utils";
import { LineItemCombined } from "types/dbSchema/invoices";

export const columns = [
  {
    key: '',
    name: '#',
    width: 'w-full',
    minWidth: 'min-w-[50px]',
    maxWidth: 'max-w-[50px]',
    renderComponent: (props: { row: LineItemCombined, index: number }) => (
      <p className={cn(
        "text-sm",
        props.row?.isInvoiceValid ? "text-text-secondary font-normal" : "text-text-incomplete font-semibold"
      )}>
        {props.index + 1}
      </p>
    )
  },
  {
    key: 'date',
    name: 'Date',
    width: 'w-full',
    minWidth: 'min-w-[120px]',
    maxWidth: 'max-w-full',
    renderComponent: (props: { row: LineItemCombined }) => (
      <p className={cn(
        "text-sm font-normal",
        props.row?.validation.date ? "text-text-primary" : "text-text-incomplete"
      )}>
        {props.row?.date ? new Date(props.row.date).toLocaleDateString() : `Not Found`}
      </p>
    )
  },
  {
    key: 'endDate',
    name: 'End Date',
    width: 'w-full',
    minWidth: 'min-w-[120px]',
    maxWidth: 'max-w-full',
    renderComponent: (props: { row: LineItemCombined }) => (
      <p className={cn(
        "text-sm font-normal",
        props.row?.validation.endDate ? "text-text-primary" : "text-text-incomplete"
      )}>
        {props.row?.endDate ? new Date(props.row.endDate).toLocaleDateString() : `Not Found`}
      </p>
    )
  },
  {
    key: 'productCode',
    name: 'Product Code',
    width: 'w-full',
    minWidth: 'min-w-[250px]',
    maxWidth: 'max-w-[300px]',
    renderComponent: (props: { row: LineItemCombined }) => (
      <>
        {props.row?.validation.productCode ?
          <p className="text-sm text-text-primary font-normal" >
            {props.row.productCode}
          </p> :
          <p className="text-sm text-text-incomplete font-normal" >
            Not found
          </p>
        }
      </>
    )
  },
  {
    key: 'quantity',
    name: 'Quantity',
    width: 'w-full',
    minWidth: 'min-w-[120px]',
    maxWidth: 'max-w-[120px]',
    renderComponent: (props: { row: LineItemCombined }) => (
      <p className={cn(
        "text-sm font-normal",
        props.row?.validation.quantity ? "text-text-primary" : "text-text-incomplete"
      )}>
        {props.row?.quantity ? props.row.quantity : `Not Found`}
      </p>
    )
  },
  {
    key: 'unit',
    name: 'Unit',
    width: 'w-full',
    minWidth: 'min-w-[120px]',
    maxWidth: 'max-w-[120px]',
    renderComponent: (props: { row: LineItemCombined }) => (
      <p className={cn(
        "text-sm font-normal",
        props.row?.validation.unit ? "text-text-primary" : "text-text-incomplete"
      )}>
        {props.row?.unit ? props.row.unit : `Not Found`}
      </p>
    )
  },
  {
    key: 'unitPrice',
    name: 'Rate',
    width: 'w-full',
    minWidth: 'min-w-[120px]',
    maxWidth: 'max-w-[120px]',
    renderComponent: (props: { row: LineItemCombined }) => (
      <p className={cn(
        "text-sm font-normal",
        props.row?.validation.unitPrice ? "text-text-primary" : "text-text-incomplete"
      )}>
        {props.row?.unitPrice ? props.row.unitPrice : `Not Found`}
      </p>
    )
  },
  {
    key: 'amount',
    name: 'Total',
    width: 'w-full',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[200px]',
    renderComponent: (props: { row: LineItemCombined }) => (
      <p className={cn(
        "text-sm font-normal",
        props.row?.validation.amount ? "text-text-primary" : "text-text-incomplete"
      )}>
        {props.row?.amount ? props.row.amount : `Not Found`}
      </p>
    )
  },
  {
    key: 'tax',
    name: 'GST',
    width: 'w-full',
    minWidth: 'min-w-[120px]',
    maxWidth: 'max-w-[120px]',
    renderComponent: (props: { row: LineItemCombined }) => (
      <p className={cn(
        "text-sm font-normal",
        props.row?.validation.tax ? "text-text-primary" : "text-text-incomplete"
      )}>
        {props.row?.tax ? props.row.tax : `Not Found`}
      </p>
    )
  },
]
