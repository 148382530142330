import React, { FC, HTMLProps, useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/button";
import { cn } from "lib/utils";
import ChevronDownIcon from "../Icons/ChevronDownIcon";
import SearchIcon from "../Icons/SearchIcon";
import CloseIcon from "../Icons/CloseIcon";

export interface FilterOption {
  label: React.ReactNode;
  value: string;
}

export interface FilterProps {
  className?: HTMLProps<HTMLElement>["className"];
  label: string;
  filters: FilterOption[];
  selectedFilters: string[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const Filter: FC<FilterProps> = ({
  className,
  label,
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [open, setOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);
  const clearSelection = () => setSelectedFilters([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search.length > 0) {
      setLocalFilters(
        filters.filter((f) =>
          f.value.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setLocalFilters(filters);
    }
  }, [search, filters]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-fit justify-start text-left font-normal text-text-primary border-border-table bg-bg-secondary",
              "flex flex-row items-center justify-between font-semibold"
            )}
          >
            {label} {selectedFilters.length > 0 && `(${selectedFilters.length})`}
            <ChevronDownIcon
              className={cn(
                "w-4 h-4",
                "transform transition-transform duration-200",
                open ? "rotate-180" : "rotate-0"
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto border-border-table bg-bg-primary flex flex-col p-0"
          align="start"
        >
          <div className="py-2 px-3 text-text-primary flex flex-row justify-between items-center gap-2">
            <div className="flex flex-row gap-2 items-center relative">
              <SearchIcon className='text-text-primary !size-4' />
              <input
                type="text"
                placeholder="Search"
                className="outline-none bg-transparent"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search.length > 0 && (
                <CloseIcon className='absolute right-0 text-text-primary !size-4 cursor-pointer' onClick={() => setSearch('')} />
              )}
            </div>
          </div>
          <div className="p-3 text-text-primary border-y border-border-table gap-3 flex flex-col">
            {localFilters.length > 0 ? localFilters.map(({ label, value }) => (
              <div key={value} className="flex gap-2 relative items-center">
                <input
                  type="checkbox"
                  id={value}
                  checked={selectedFilters.includes(value)}
                  onChange={() => {
                    if (selectedFilters.includes(value)) {
                      setSelectedFilters(selectedFilters.filter((f) => f !== value));
                    } else {
                      setSelectedFilters([...selectedFilters, value]);
                    }
                  }}
                  className={cn(
                    "relative peer shrink-0 appearance-none w-4 h-4 border border-border-main cursor-pointer",
                    "rounded-sm bg-nav-bg checked:bg-text-tertiary checked:border-0"
                  )}
                />
                <label
                  htmlFor={value}
                  className={cn(
                    "flex items-center justify-start text-left font-normal text-text-primary cursor-pointer",
                  )}
                >
                  {label}
                </label>
                <svg
                  className="absolute w-4 h-4 mt-1 hidden peer-checked:block cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style={{ left: '0px', margin: 'auto' }}
                  onClick={() => {
                    if (selectedFilters.includes(value)) {
                      setSelectedFilters(selectedFilters.filter((f) => f !== value));
                    } else {
                      setSelectedFilters([...selectedFilters, value]);
                    }
                  }}
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </div>
            )) : (
              <p className="text-text-primary text-sm">No results found</p>
            )}
          </div>
          <div className="py-2 px-3 text-text-primary min-h-9">
            {selectedFilters.length > 0 && (
              <div onClick={clearSelection} className="cursor-pointer">
                <p className="text-text-primary text-sm font-semibold">Clear selection</p>
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Filter;