import { atom } from "jotai";

export interface CurrentModal {
  id: string;
  component: React.ComponentType<any>
  props?: any;
}

const currentModalsAtom = atom<CurrentModal[]>([]);

export default currentModalsAtom;