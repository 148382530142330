import AnalyticsIcon from "components/common/Icons/AnalyticsIcon";
import DocumentArrowDownIcon from "components/common/Icons/DocumentArrowDownIcon";
import SettingsIcon from "components/common/Icons/SettingsIcon";
import AnalyticsPage from "components/pages/Analytics";
import HomePage from "components/pages/Home";
import InvoicesPage from "components/pages/Invoices";
import FullscreenInvoicePage from "components/pages/Invoices/Fullscreen";
import ViewInvoicePage from "components/pages/Invoices/View";
import KeysPage from "components/pages/Settings/Keys";
import { NavigationTab, RouteConfig } from "types";

// The catch all route is used to redirect the user if the route does not exist
export const catchAllRoute = '/';
// Public routes are routes that do not require authentication
export const publicRoutes = ['/'];

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: HomePage,
    useLayout: false,
    isIndex: true,
  },
  {
    path: '/invoices',
    component: InvoicesPage,
    useLayout: true,
  },
  {
    path: '/invoices/:id',
    component: ViewInvoicePage,
    useLayout: true,
  },
  {
    path: '/invoices/:id/fullscreen',
    component: FullscreenInvoicePage,
    useLayout: false,
  },
  {
    path: '/analytics',
    component: AnalyticsPage,
    useLayout: true,
  },
  {
    path: '/settings',
    component: KeysPage,
    useLayout: true,
  },
  {
    path: '/settings/keys',
    component: KeysPage,
    useLayout: true,
  },
];

export const navigationTabs: NavigationTab[] = [
  {
    label: 'Invoices',
    path: '/invoices',
    icon: DocumentArrowDownIcon
  },
  {
    label: 'Analytics',
    path: '/analytics',
    icon: AnalyticsIcon
  },
  {
    label: 'Settings',
    path: '/settings/keys',
    icon: SettingsIcon
  },
];