import MonthPickerPopover from 'components/common/MonthPickerPopover'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { options } from './options';
import Filter from 'components/common/Filter';
import { resultFilters } from 'components/common/Result';

const AnalyticsChart = () => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  // const [options] = useState(options);

  const [series, setSeries] = useState([
    {
      name: 'Complete',
      data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43],
      color: 'var(--complete)'
    },
    {
      name: 'Incomplete',
      data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27],
      color: 'var(--incomplete)'
    },
    {
      name: 'Failed',
      data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27],
      color: 'var(--failed)'
    }
  ]);

  return (
    <div className='flex flex-col w-full h-full p-6 text-text-primary border border-border-table rounded-xl gap-10'>
      <div className='flex flex-row items-center justify-start w-full gap-6'>
        <p className='text-lg font-semibold'>
          Processed invoices by status
        </p>
        <MonthPickerPopover />
        <Filter
          label='Result'
          filters={resultFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
      <div className='flex flex-col w-full h-full gap-4'>
        <div className='w-full flex flex-row items-center justify-start gap-16'>
          <div className='flex flex-col gap-1 items-start justify-start'>
            <p className='text-2xl font-semibold'>23457</p>
            <p className='text-sm font-semibold text-complete'>Total Complete</p>
          </div>
          <div className='flex flex-col gap-1 items-start justify-start'>
            <p className='text-2xl font-semibold'>23457</p>
            <p className='text-sm font-semibold text-incomplete'>Total Incomplete</p>
          </div>
          <div className='flex flex-col gap-1 items-start justify-start'>
            <p className='text-2xl font-semibold'>23457</p>
            <p className='text-sm font-semibold text-failed'>Total Failed</p>
          </div>
        </div>
        <div className='w-full h-full'>
          <ReactApexChart
            options={options as any}
            series={series}
            type="bar"
            height='100%'
            width="100%"
          />
        </div>
      </div>
    </div>
  )
}

export default AnalyticsChart