import axiosInstance from "api/config";
import { ApiKey } from "types/dbSchema/apikeys";

export const getApiKeys = async (): Promise<ApiKey[]> => {
  try {
    const response = await axiosInstance.get('/api/apikey');
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const createApiKey = async (data: any): Promise<ApiKey> => {
  try {
    const response = await axiosInstance.post('/api/apikey', data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the api key', error);
    throw error;
  }
};

export const deleteApiKey = async (apiKeyPublicId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/api/apikey/${apiKeyPublicId}`);
  } catch (error) {
    console.error('There was an error deleting the api key', error);
    throw error;
  }
};