import { cn } from 'lib/utils';
import { FC, HTMLProps } from 'react';

export interface FullscreenLoadingIndicatorProps {
  className?: HTMLProps<HTMLElement>["className"]
}

const FullscreenLoadingIndicator: FC<FullscreenLoadingIndicatorProps> = ({ className }) => {
  return (
    <div className={cn(
      "fixed inset-0 flex items-center justify-center bg-bg-primary bg-opacity-20 z-40",
      className
    )}>
      <LoadingIndicator />
    </div>
  );
};

export const LoadingIndicator: FC<FullscreenLoadingIndicatorProps> = ({ className }) => {
  return (
    <div className={cn(
      "w-12 h-12 rounded-full animate-spin border-8 border-solid border-button-primary border-t-transparent",
      className
    )} />
  )
}


export default FullscreenLoadingIndicator;