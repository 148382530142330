import currentModalsAtom from "atoms/currentModalsAtom"
import { useAtom } from "jotai"
import { cn } from "lib/utils";
import { createElement } from "react";

const ModalProvider = () => {
  const [currentModals, setCurrentModals] = useAtom(currentModalsAtom);

  const handleClose = (id: string) => {
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== id));
  }

  if (!currentModals.length) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {currentModals.map(({ component, id, props }, index) => (
        <div
          onClick={() => handleClose(id)}
          key={index}
          className={cn(
            "fixed inset-0 z-50 flex items-center justify-center",
            index === 0 ? "bg-black bg-opacity-50" : ""
          )}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative z-50"
          >
            {createElement(component, { ...props })}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ModalProvider