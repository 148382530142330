import axiosInstance from "api/config";
import { InvoiceOverview, InvoiceOverviewList } from "types/dbSchema/invoices";

export type InvoiceState = 'Uploaded' | 'Processing' | 'Completed' | 'Error' | 'Approved';

export const getInvoices = async (
  pageNumber: number,
  pageSize: number,
  sortField: string,
  sortOrder: string,
  startDate?: string,
  endDate?: string,
  states?: InvoiceState[],
): Promise<InvoiceOverviewList> => {
  try {
    const response = await axiosInstance.get('/api/invoice/list', {
      params: {
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        startDate,
        endDate,
        states
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const getInvoicesBySearch = async (
  searchText: string,
  pageNumber: number,
  pageSize: number,
  sortField: string,
  sortOrder: string,
  invoiceDateFrom?: string,
  invoiceDateTo?: string,
  states?: InvoiceState[],
): Promise<InvoiceOverviewList> => {
  try {
    const response = await axiosInstance.get('/api/invoice/search', {
      params: {
        searchText,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        invoiceDateFrom,
        invoiceDateTo,
        states
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const getInvoiceById = async (publicFileId: string): Promise<InvoiceOverview> => {
  try {
    const response = await axiosInstance.get(`/api/invoice/${publicFileId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const getInvoiceImage = async (filedId: string, page: number): Promise<InvoiceOverview> => {
  try {
    const response = await axiosInstance.get(`/api/invoice/image/${filedId}/${page}`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const getInvoiceImageByUrl = async (url: string): Promise<Blob> => {
  try {
    const response = await axiosInstance.get(url, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const getInvoiceImageUrls = async (filedId: string): Promise<InvoiceOverview> => {
  try {
    const response = await axiosInstance.get(`/api/invoice/image-urls/${filedId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};


export const uploadInvoice = async (formData: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/api/invoice/upload`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const uploadInvoiceBatch = async (formData: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/api/invoice/upload/batch`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};