import { keepPreviousData, useQuery } from "@tanstack/react-query"
import FullscreenLoadingIndicator from "components/common/FullscreenLoadingIndicator"
import Table from "components/common/Table"
import useInvalidateData from "hooks/useInvalidateData"
import { cachingInvalidation } from "lib/utils/cachingConfig"
import { useEffect, useMemo, useState } from "react"
import { Title } from "./Title"
import { columns } from "./Columns"
import { getApiKeys } from "api/apikey"
import { ApiKey } from "types/dbSchema/apikeys"

const KeysTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [newAPIKeys, setNewAPIKeys] = useState<ApiKey[] | null>(null);
  useInvalidateData(cachingInvalidation.tableName.apiKeys);

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: [cachingInvalidation.tableName.apiKeys, currentPage, rowsPerPage],
    queryFn: () => getApiKeys(),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.time.invoices,
  });

  // Handle adding the newAPIKey to the data without refetching to retain the exposed API key value
  const modifiedData = useMemo(() => {
    const baseData = data ?? [];
    return newAPIKeys && newAPIKeys.length > 0
      ? [...newAPIKeys, ...baseData]
      : baseData;
  }, [data, newAPIKeys]);

  // Clear the newAPIKey when data is refetching
  useEffect(() => {
    if (isFetching) setNewAPIKeys(null);
  }, [isFetching]);

  if (isPending) return <FullscreenLoadingIndicator />

  return (
    <div className="flex flex-col w-full h-full gap-4 max-w-[1600px]">
      <Title setNewAPIKeys={setNewAPIKeys} />
      <Table
        columns={columns}
        data={modifiedData}
        heightOffset={0}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={newAPIKeys ? (data?.length ?? 0) + 1 : data?.length ?? 0}
        isError={isError}
        isFetching={isFetching}
        pageNumbersOptions={[100]}
        footerVariant="disabled"
      />
    </div>
  )
}

export default KeysTable