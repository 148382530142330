import { FC, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useInvalidateData from 'hooks/useInvalidateData'
import { cachingInvalidation } from 'lib/utils/cachingConfig'
import FullscreenLoadingIndicator from 'components/common/FullscreenLoadingIndicator'
import Title from './Title'
import Table from 'components/common/Table'
import { columns } from './Columns'
import { useNavigate } from 'react-router-dom'
import { getInvoices } from 'api/invoice'
import { DateRange } from 'react-day-picker'

const InvoicesTable: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [date, setDate] = useState<DateRange | undefined>(undefined)
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')
  useInvalidateData(cachingInvalidation.tableName.invoices);
  const navigate = useNavigate();

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: [cachingInvalidation.tableName.invoices, currentPage, rowsPerPage, date?.from, date?.to],
    queryFn: () => getInvoices(
      currentPage,
      rowsPerPage,
      'UploadDate',
      'desc',
      date?.from && date?.from.toISOString(),
      date?.to && date?.to.toISOString(),
    ),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.time.invoices,
  });

  // Commented out while we work out the advanced search feature
  // const { isPending, isError, data, isFetching, } = useQuery({
  //   queryKey: ['invoices', currentPage, rowsPerPage, search],
  //   queryFn: () => getInvoicesBySearch(search, currentPage, rowsPerPage, 'UploadDate', 'desc'),
  //   placeholderData: keepPreviousData,
  //   refetchOnWindowFocus: false,
  //   staleTime: cachingInvalidation.time.invoices,
  // });

  const handleRowClick = (row: any) => navigate(`/invoices/${row.publicId}`);

  if (isPending) return <FullscreenLoadingIndicator />

  return (
    <div className="flex flex-col w-full h-full gap-4 max-w-[1600px]">
      <Title
        date={date}
        selectedFilters={selectedFilters}
        search={search}
        setDate={setDate}
        setSelectedFilters={setSelectedFilters}
        setSearch={setSearch}
      />
      <Table
        columns={columns}
        data={data?.data || []}
        heightOffset={142}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={data?.totalCount ?? 0}
        isError={isError}
        isFetching={isFetching}
        onRowClick={handleRowClick}
        pageNumbersOptions={[10, 20, 50]}
      />
    </div>
  )
}

export default InvoicesTable