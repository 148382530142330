import Button from 'components/common/Button'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const HomePage: FC = () => {
  const navigate = useNavigate()
  return (
    <div className='p-8 w-screen h-screen absolute top-0 left-0 gap-2 flex flex-col items-center justify-center text-text-primary'>
      Home Placeholder - Marketing Website
      <Button
        variant='primary'
        type='button'
        onClick={() => navigate('/invoices')}
      >
        Got to app
      </Button>
    </div>
  )
}

export default HomePage