import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewInvoiceSection from './ViewInvoiceSection';
import DetailsSection from './ViewDetailsSection';
import { getInvoiceById } from 'api/invoice';
import { InvoiceOverview } from 'types/dbSchema/invoices';
import FullscreenLoadingIndicator from 'components/common/FullscreenLoadingIndicator';
import { ViewInvoiceProps } from './types';

const ViewInvoice: FC<ViewInvoiceProps> = ({
  currentInvoice,
}) => {
  const [rightWidth, setRightWidth] = useState(500);
  const maxWidth = 800;
  const minWidth = 300;

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    const startX = e.clientX;

    const onMouseMove = (e: MouseEvent) => {
      const newRightWidth = rightWidth - (e.clientX - startX);
      setRightWidth(Math.min(Math.max(newRightWidth, minWidth), maxWidth));
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex-1 h-full overflow-auto">
        <DetailsSection currentInvoice={currentInvoice} />
      </div>
      <div
        style={{ width: rightWidth }}
        className="h-full overflow-auto bg-bg-invoice flex flex-row relative transition-[background-color] ease-in-out duration-300"
      >
        <div
          className="w-2 h-full cursor-col-resize absolute left-0 top-0 border-l border-border-table z-30"
          onMouseDown={handleMouseDown}
        />
        <ViewInvoiceSection currentInvoice={currentInvoice} />
      </div>
    </div>
  );
};

export default ViewInvoice;