import { getInvoiceImageByUrl } from "api/invoice";
import { AuthenticatedState } from "atoms/currentAuthenticatedStateAtom/types";
import { clsx, type ClassValue } from "clsx"
import DocumentIcon from "components/common/Icons/DocumentIcon";
import { twMerge } from "tailwind-merge"
import { ProductFamilyName, SubscriptionUsage } from "types";
import { InvoiceOverview, InvoiceResult } from "types/dbSchema/invoices";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const fileIconMap: { [key: string]: string } = {
  avi: '/assets/fileIcons/AVI.svg',
  csv: '/assets/fileIcons/CSV.svg',
  doc: '/assets/fileIcons/DOC.svg',
  docx: '/assets/fileIcons/DOCX.svg',
  gif: '/assets/fileIcons/GIF.svg',
  img: '/assets/fileIcons/IMG.svg',
  jpeg: '/assets/fileIcons/JPEG.svg',
  jpg: '/assets/fileIcons/JPG.svg',
  mkv: '/assets/fileIcons/MKV.svg',
  mp3: '/assets/fileIcons/MP3.svg',
  mp4: '/assets/fileIcons/MP4.svg',
  ogg: '/assets/fileIcons/OGG.svg',
  pdf: '/assets/fileIcons/PDF.svg',
  png: '/assets/fileIcons/PNG.svg',
  ppt: '/assets/fileIcons/PPT.svg',
  pptx: '/assets/fileIcons/PPTX.svg',
  rar: '/assets/fileIcons/RAR.svg',
  txt: '/assets/fileIcons/TXT.svg',
  wav: '/assets/fileIcons/WAV.svg',
  webp: '/assets/fileIcons/WEBP.svg',
  xls: '/assets/fileIcons/XLS.svg',
  xlsx: '/assets/fileIcons/XLSX.svg',
  zip: '/assets/fileIcons/ZIP.svg',
};

export const getFileIcon = (fileType: string) => {
  if (!fileType) return <DocumentIcon className="size-8 text-text-primary" />;
  for (const key in fileIconMap) {
    if (fileType.includes(key)) return <img src={fileIconMap[key]} alt={`${key}-icon`} />;
  }
  return <DocumentIcon className="size-8 text-text-primary" />;
};

export const formatFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1024 * 1024) {
    const kbSize = (sizeInBytes / 1024).toFixed(1);
    return `${kbSize} KB`;
  } else {
    const mbSize = (sizeInBytes / (1024 * 1024)).toFixed(1);
    return `${mbSize} MB`;
  }
};

export const getImgSrcArray = async (imageUrls: string[]): Promise<string[]> => {
  const imgSrcArray: string[] = [];

  for (const url of imageUrls) {
    try {
      // Fetch the Blob directly
      const blob = await getInvoiceImageByUrl(url);

      // Create a Blob URL
      const objectURL = URL.createObjectURL(blob);

      // Add the Blob URL to the array
      imgSrcArray.push(objectURL);
    } catch (error) {
      console.error(`Failed to process URL: ${url}`, error);
    }
  }

  return imgSrcArray;
};

export const getNestedValue = (obj: any, key: string): any => {
  return key.split('.').reduce((o, k) => (o ? o[k] : undefined), obj);
};

export const getInvoiceResult = (currentInvoice: InvoiceOverview | null): InvoiceResult => {
  if (currentInvoice?.state === 'Processing' || currentInvoice?.state === 'Uploaded') {
    return 'Processing';
  } else if (currentInvoice?.state === 'Error') {
    return 'Failed';
  } else if (currentInvoice?.validation.complete) {
    return 'Complete';
  } else if (!currentInvoice?.validation.complete) {
    return 'Incomplete';
  } else {
    return 'Failed';
  }
};

// This function will grow as we add in more products
export const getUsage = (currentAuthenticatedState: AuthenticatedState | null, productFamilyName: ProductFamilyName): SubscriptionUsage => {
  const usage = {
    limit: -1,
    used: -1,
    remaining: -1,
  } as SubscriptionUsage;

  if (!currentAuthenticatedState) return usage;

  const subscriptions = currentAuthenticatedState?.tenant.subscriptions;


  if (subscriptions.filter(
    subscription => subscription.productFamily === productFamilyName).length === 0) return usage;

  // If there are no transactions, then there has not been any usage for this period yet
  if (subscriptions[0].subscriptionTransactions.length === 0) {
    usage.limit = subscriptions[0].includedTransactionCount;
    usage.used = 0;
    usage.remaining = usage.limit;
    return usage;
  } else {
    usage.limit = subscriptions[0].includedTransactionCount;
    usage.used = subscriptions[0].subscriptionTransactions[0].transactionCount;
    usage.remaining = usage.limit - usage.used;
    return usage;
  }
}
