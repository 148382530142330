import InvoicesTable from 'components/features/InvoicesTable'
import { FC } from 'react'

const InvoicesPage: FC = () => {
  return (
    <div className='p-8 w-full h-full flex items-center justify-center'>
      <InvoicesTable />
    </div>
  )
}

export default InvoicesPage