import Result from "components/common/Result";
import { Column } from "components/common/Table/types";
import { getInvoiceResult } from "lib/utils";
import { InvoiceOverview } from "types/dbSchema/invoices";

export const columns: Column[] = [
  {
    key: 'invoice.providerName',
    name: 'Service Provider',
    width: 'w-1/4',
    minWidth: 'min-w-[220px]',
    maxWidth: 'max-w-[300px]',
  },
  {
    key: 'invoice.participantName',
    name: 'Participant',
    width: 'w-1/4',
    minWidth: 'min-w-[220px]',
    maxWidth: 'max-w-[300px]',
  },
  {
    key: 'invoice.invoiceNumber',
    name: 'Invoice Number',
    width: 'w-full',
    minWidth: 'min-w-[220px]',
    maxWidth: 'max-w-full',
  },
  {
    key: 'result',
    name: 'Result',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
    renderComponent: (props: { row: InvoiceOverview }) => (
      <Result result={getInvoiceResult(props.row)} />
    )
  },
  {
    key: 'uploadDate',
    name: 'Date Submitted',
    width: 'w-1/4',
    minWidth: 'min-w-[120px]',
    maxWidth: 'max-w-[200px]',
    renderComponent: (props: { row: InvoiceOverview }) => (
      <p className=" text-sm text-text-primary font-normal">
        {new Date(props.row.uploadDate).toLocaleDateString()}
      </p>
    )
  }
]